import React from 'react';
import styles from './main.module.scss';
import {CHMainCarousel} from "../../components/main-carousel/main-carousel";
import {CauseCarousel} from "../../components/cause-carousel/cause-carousel";
import {InfoContainer} from "../../components/info-container/info-container";
import {CategoryCard} from "../../components/category-card/category-card";
import {Container, Row} from "react-bootstrap";
//import {mockCategories} from "../../data/categories-mock";
import {CHTabs} from "../../components/tabs/tabs";
import Facts from "../../components/facts/facts";
import {RecentNewsCarousel} from "../../components/recent-news-carousel/recent-news-carousel";
//import {ProudPartners} from "../../components/proud-partners/proud-partners";

//import topEvents from "../../data/top-events-mock.json";
//import recentNews from "../../data/recentNewsMock.json";
//import proudPartners from "../../data/proudPartnersMock.json";
import {connect} from "react-redux";
import {CauseCard} from "../../components/cause-card/cause-card";
import VBox from "../../components/ui/containers/layouts/vbox";
import HBox from "../../components/ui/containers/layouts/hbox";
import {Switcher} from "../../components/switcher/switcher";

const Main = ({ projects, categories, wording, events, news }) => {

    const completedPages = [wording['main-completed-1'], wording['main-completed-2'], wording['main-completed-3']];
    const clarifications = {
        [wording['main-completed-1']]: wording['main-completed-1-clarification'],
        [wording['main-completed-2']]: wording['main-completed-2-clarification'],
        [wording['main-completed-3']]: wording['main-completed-3-clarification'],
    };

    const [completedPage, setCompletedPage] = React.useState(completedPages[0]);


    const getCategories = () => {
        if (!categories) return null;
        const allProjectCategories = Array.from(new Set(projects.map(project => project.categories).flat()))
        const filteredCategories = categories.filter(category => allProjectCategories.includes(category.id));
        return filteredCategories.map((category, index) => <CategoryCard wording={wording} {...category} key={index} />)
    };

    let completedProjects = []
    if ((completedPage === completedPages[0])) completedProjects = projects.filter(el => el.status === 'completed');
    if ((completedPage === completedPages[1])) completedProjects = projects.filter(el => (el.status === 'failed' && el.raised >= el.goal * 0.2 && el.raised < el.goal * 0.5));
    if ((completedPage === completedPages[2])) completedProjects = projects.filter(el => ((el.status === 'failed' && el.raised < el.goal * 0.2) || el.status === 'refund'));

    return (
        <div className={styles.main}>
            <CHMainCarousel wording={wording} carouselData={projects.filter(el => el.status === 'approved')} />
            <CauseCarousel wording={wording} causes={projects.filter(el => el.status === 'approved')} />
            <InfoContainer
                title={wording['categories-heading']}
                text={wording['categories-description']}
            >
                <Row className={styles.categoriesList}>
                    {getCategories()}
                </Row>
            </InfoContainer>
            <Container>
                <div className={styles.causeDetails}>
                    <VBox gap={10} width={'100%'}>
                            <h2 className={styles.title} style={{paddingBottom: 0}}>
                                {wording['main-completed-projects']}
                            </h2>
                        <Switcher pages={completedPages} page={completedPage} setPage={setCompletedPage} />

                        <HBox width={'100%'} sb style={{marginBottom: 20, marginTop: 20}}>
                            <div>{clarifications[completedPage]}</div>
                        </HBox>
                    </VBox>
                    {!!completedProjects.length &&
                        <div className={styles.relatedCauses}>
                            {completedProjects.map((cause, causeIndex) => (
                                <div key={causeIndex} className={styles.relatedCausesCard}>
                                    <CauseCard wording={wording} mini cause={cause}/>
                                </div>
                            ))}
                        </div>
                    }
                </div>
            </Container>
            <InfoContainer
                title={wording['events-heading']}
                text={wording['events-description']}
            >
                <CHTabs events={events} wording={wording} />
            </InfoContainer>
            <Facts bgImage={'https://wallpapers.com/images/hd/blue-aesthetic-moon-df8850p673zj275y.jpg'} className={styles.factCircle} />
            <InfoContainer
                title={wording['news-heading']}
                text={wording['news-description']}
                white
            >
                <RecentNewsCarousel wording={wording} recentNews={news} />
            </InfoContainer>
        </div>
    )
}

const mapStateToProps = ({  projects: { projects }, system: { wording, categories, events, news } }) => {
    return { projects, wording, categories, events, news };
};

export default connect(mapStateToProps)(Main);
